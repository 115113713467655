import { sortBy } from 'lodash'
import { useSnapshot } from 'valtio'
import { signalrHooks2 } from '../SDK/Signalr/signalrHooks2'
import { useSortSignalrDataStore } from '../symbolQuote/simple/useSortStore'

export const useSignalr2QuoteSort = (data: string[]) => {
  const sortSnap = useSnapshot(useSortSignalrDataStore)
  const sortKey = sortSnap.sortKey
  const orderKey = sortSnap.orderKey

  const value = signalrHooks2.useQuotes(data)
  const valueData = () => {
    if (sortKey === 'change')
      return sortBy(value, datum => (datum?.close ?? 0) - (datum?.prevRef ?? 0)).map(
        datum => datum?.symbol,
      )
    else if (sortKey === 'changePrecent')
      return sortBy(value, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01).map(
        datum => datum?.symbol,
      )
    else if (sortKey === 'none') return value.map(datum => datum?.symbol)
    else if (sortKey === sortKey)
      return sortBy(value, datum => datum?.[sortKey]).map(datum => datum?.symbol)
  }

  const resultData = orderKey === 'desc' ? valueData()?.reverse() : valueData()

  return resultData
}
