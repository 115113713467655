import styled from '@emotion/styled'
import { memo, useEffect } from 'react'
import { useSnapshot } from 'valtio'
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2'
import { store } from '~/pages/heineken_template/_private/store'
import QuoteValueItem from '~/modules/investment-consultant/quoteValueBody/QuoteValueBody'
import {
  fill_horizontal_all_center,
  fill_horizontal_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2'
import { useThemeStore } from '~/components/theme/useThemeStore'
import { css } from '@emotion/react'

const QuoteDetail = memo<ReactProps<{ symbol: string[] }>>(function QuoteDetail(props) {
  const charting = useSnapshot(store.charting)
  const currentSymbol = charting.symbol
  const theme = useThemeStore(s => s.theme)

  useEffect(() => {
    signalrStore2.addQuote(currentSymbol)
    return () => {
      signalrStore2.removeQuote(currentSymbol)
    }
  }, [JSON.stringify(currentSymbol)])

  const value = useSnapshot(signalrStore2.values.quote)[currentSymbol]
  return (
    <classes.Root css={baseStyled}>
      <classes.container className={theme}>
        <span>成交</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'close'}
        />
        <span>單量</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'qty'}
        />
        <span>買進</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'bid'}
        />
        <span>賣出</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'ask'}
        />
        <span>最高</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'high'}
        />
        <span>最低</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'low'}
        />
        <span>開盤</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'open'}
        />
        <span>參考</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'prevRef'}
        />
        <span>總量</span>
        <QuoteValueItem.ohlc.Display
          quote={value}
          ohlcType={'volume'}
        />
        <span>漲跌</span>
        <QuoteValueItem.change.Display
          quote={value}
          changeType='closeChange'
        />
      </classes.container>
    </classes.Root>
  )
})

const classes = {
  Root: styled.div`
    width: 100%;
    height: 100%;
  `,
  container: styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;

    border-radius: 4px;
    padding: 2px;
    font-size: 14px;
    & > div {
      ${fill_horizontal_cross_center};
      justify-content: start;
      height: 24px;
    }
    & > span {
      ${fill_horizontal_cross_center};
      justify-content: center;
      height: 24px;
    }
  `,
}

const baseStyled = css`
  ${classes.container} {
    &.dark {
      background-color: #26272c;
    }
    &.light {
      background-color: #fafafa;
    }
  }
`

export default {
  Display: QuoteDetail,
  classes,
}
