import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import {
  fill_vertical_all_center,
  fill_vertical_cross_center,
} from '~/modules/AppLayout/FlexGridCss'
import TitleSymbolQuote from '~/modules/investment-consultant/symbolList/body/SymbolQuoteTitle'
import { useSnapshot } from 'valtio'
import { store } from '~/pages/heineken_template/_private/store'
import IntradayTrendChart from '~/modules/trendChart'
import DarkGauge from './component/DarkGauge'
import { TrendScore } from './component/TrendScore'
import { PriceBuild } from './component/PriceBuild'
import { asia71178_store } from './asia71178_store'
import QuoteDetail from '../win988/component/QuoteDetail'

export const Asia71178_SidePane1 = memo<ReactProps>(function Asia71178_SidePane1() {
  const charting = useSnapshot(store.charting)
  const symbolNumber = charting.symbol

  const powerValue = useSnapshot(asia71178_store).powerValue
  //const marketValue = getIndicatorAngular(powerValue * 1000) * 100

  return (
    <styleds.container>
      {/* <PriceGauges currentValue={marketValue} /> */}
      <styleds.title>主力買盤</styleds.title>
      <styleds.componentCard height={'136px'}>
        <DarkGauge.Display
          currentValue={powerValue}
          fillType='default'
        >
          主力買盤
        </DarkGauge.Display>
      </styleds.componentCard>
      <styleds.title>趨勢得分</styleds.title>
      <styleds.componentCard height={'68px'}>
        <TrendScore />
      </styleds.componentCard>
      <styleds.componentCard height={'40px'}>
        <PriceBuild />
      </styleds.componentCard>
      <styleds.componentCard height={'300px'}>
        <TitleSymbolQuote.Default symbol={symbolNumber} />
        <div
          css={css`
            height: 134px;
          `}
        >
          <IntradayTrendChart
            symbol={symbolNumber}
            ticksSize={14}
            ticksHeight={20}
            priceTicksMargin={2.5}
            priceTicksSize={14}
          />
        </div>
        <div
          css={css`
            height: 140px;
          `}
        >
          <QuoteDetail.Display symbol={[symbolNumber]} />
        </div>
      </styleds.componentCard>
    </styleds.container>
  )
})

const styleds = {
  container: styled.div`
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    border-left: 1px solid #d6dbe3;
  `,
  componentCard: styled.div<{ height: string }>`
    width: 100%;
    height: ${props => props.height};
    padding: 4px;
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
  `,
  title: styled.div`
    ${fill_vertical_all_center};
    height: 26px;
    border-radius: 3px;
    background-color: #e4e3e3;
    box-shadow: 0px 1px 5px 1px #cccccc;
  `,
  symbolList: styled.div`
    width: 100%;
    height: calc(100% - 500px);
    border-radius: 6px;
    box-shadow: 0px 1px 5px 1px #cccccc;
    padding: 4px;
  `,
}
