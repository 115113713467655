import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'
import { fill_vertical_cross_center } from '~/modules/AppLayout/FlexGridCss'

/** 自製的行情轉速表 底部有黑色圓形介面可以顯示數值及名稱 可以帶入`0~100`的值 */
const DarkGauge = memo<ReactProps<{ currentValue: number; fillType: 'default' | 'gradient' }>>(
  function DarkGauge(props) {
    const fillType = props.fillType ?? 'default'
    const conicStyled = fillType === 'default' ? defaultColor : gradientColor

    return (
      <div
        css={css`
          ${fill_vertical_cross_center};
          height: 128px;
          width: 100%;
        `}
      >
        <classes.gauge
          deg={props.currentValue}
          conicStyled={conicStyled}
        ></classes.gauge>
        <classes.outSide />
        <classes.infomation>
          <classes.infoTitle>{props.children}</classes.infoTitle>
          <classes.infoValue>{props.currentValue.toFixed(2)}</classes.infoValue>
        </classes.infomation>
      </div>
    )
  },
)

const classesFill = {
  conicFillArray: ['#4f9e40', '#86c96c', '#b1d3a2', '#dbdbd1', '#e8c4c3', '#d79896', '#d6524d'],
}

export default {
  Display: DarkGauge,
  classesFill,
}

/** 漸層效果的上色程式碼 */
const gradientColor = classesFill.conicFillArray
  .map((color, index) => {
    const startAngle = Math.round((index * 180) / classesFill.conicFillArray.length)
    return `${color} ${startAngle}deg`
  })
  .join(', ')

/** 分隔顏色效果的上色程式碼 */
const defaultColor = classesFill.conicFillArray
  .map((color, index) => {
    const startAngle = Math.round((index * 180) / classesFill.conicFillArray.length)
    const endAngle = Math.round(((index + 1) * 180) / classesFill.conicFillArray.length)
    return `${color} ${startAngle}deg, ${color} ${endAngle}deg`
  })
  .join(', ')

const classes = {
  infoValue: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  `,
  infoTitle: styled.div`
    font-size: 12px;
    color: #ffffff;
  `,
  infomation: styled.div`
    margin-top: -40px;
    z-index: 10;
    color: #ffffff;
  `,
  outSide: styled.div`
    position: absolute;
    width: 248px;
    height: 128px;
    z-index: 1;
    background: #11111111;
    border-radius: 120px 120px 0 0;
    border: 1px solid #cacaca;
    border-width: 0px 0px 0px 0px;
  `,
  gauge: styled.div<{ deg: number; conicStyled: string }>`
    position: relative;
    width: 224px;
    height: 110px;
    border-radius: 116px 116px 0 0;
    margin-top: 12px;
    overflow: hidden;
    background: radial-gradient(circle at bottom center, #353535 60px, #656565, transparent 64px),
      conic-gradient(from 4.7rad at 50% 100%, ${props => props.conicStyled});

    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 12px;
      width: 100px;
      height: 6px;
      background: linear-gradient(18deg, #353535, #454545);
      border-radius: 50%;
      box-shadow: 4px 2px 2px #555555;
      clip-path: polygon(-10px -10px, 40px -10px, 38px 10px, -10px 10px);
      transform-origin: center right;
      transform: rotate(${props => props.deg * 1.8}deg);
      transition: 1s;
    }

    // /* safari fix */
    // -webkit-transform-style: flat;
    // -webkit-transform: translateZ(0px);
  `,
}

//漸層
// #4f9e40 0deg,
// #86c96c 25.7deg,
// #b1d3a2 51.4deg,
// #dbdbd1 77.1deg,
// #e8c4c3 102.8deg,
// #d79896 128.5deg,
// #d6524d 180deg

//
// #4f9e40 0deg 25.7deg,
// #86c96c 25.7deg 51.4deg,
// #b1d3a2 51.4deg 77.1deg,
// #dbdbd1 77.1deg 102.8deg,
// #e8c4c3 102.8deg 128.5deg,
// #d79896 128.5deg 154.2deg,
// #d6524d 154.2deg 180deg
