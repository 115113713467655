import { css } from '@emotion/react'
import { Loader } from '@mantine/core'
import { memo } from 'react'
import { fill_vertical_all_center } from '../AppLayout/FlexGridCss'

const LoadingCard = memo<ReactProps>(function LoadingCard() {
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
      `}
    >
      <Loader />
    </div>
  )
})

const EmptyCard = memo<ReactProps>(function EmptyCard() {
  return (
    <div
      css={css`
        ${fill_vertical_all_center};
      `}
    >
      <div>目前沒有商品</div>
    </div>
  )
})

export default {
  Loading: LoadingCard,
  emtpty: EmptyCard,
}
