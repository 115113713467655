import { createIndicator } from '~/modules/SDK/indicator/createIndicator'
import dayAPI from '~/utils/dayAPI'

export const asia71178_gwa = createIndicator({
  id: 'asia71178-gwa',
  displayName: 'GWA',
  constructorScope: {
    init(context, inputCallback) {
      return
    },
    main(context, inputCallback) {
      //#region Input params
      const p1 = this._input(0)
      const p2 = this._input(1)
      const n1 = this._input(2)
      const n2 = this._input(3)
      const n = this._input(4)
      const p = this._input(5)
      const q = this._input(6)
      const k = this._input(7)
      const loadBars = this.maxList([p1, p2, n1, n2, n, p, q, k])
      //#endregion

      //#region prepare series

      const close = this.ohlc.closeArray
      const wma1 = context.new_var()
      const wma2 = context.new_var()
      const rsi1 = context.new_var()
      const rsi2 = context.new_var()
      const ama = context.new_var()
      const mx = context.new_var()
      const amb = context.new_var()
      const ambDiff = context.new_var()
      const ss = context.new_var()
      const priceChange = context.new_var()
      const priceChangeAbs = context.new_var()

      close.get(loadBars)
      wma1.get(loadBars)
      wma2.get(loadBars)
      rsi1.get(loadBars)
      rsi2.get(loadBars)
      ama.get(loadBars)
      mx.get(loadBars)
      amb.get(loadBars)
      ambDiff.get(loadBars)
      ss.get(loadBars)
      priceChange.get(loadBars)
      priceChangeAbs.get(loadBars)

      //#endregion

      //#region Indicators
      const amaValue = this.adaptiveMovingAverage(close, n, p, q)
      ama.get(loadBars)
      const wmaValue1 = this.PineJS.Std.wma(close, p1, context)
      const wmaValue2 = this.PineJS.Std.wma(close, p2, context)

      priceChange.set(this.maxList([0, close.get(0) - close.get(1)]))
      priceChangeAbs.set(Math.abs(close.get(0) - close.get(1)))
      // console.log('price change:', priceChange.get(0), priceChange.get(1))
      // console.log('price change abs:', priceChangeAbs.get(0), priceChangeAbs.get(1))

      const rsiValue1 =
        (this.PineJS.Std.sma(priceChange, n1, context) /
          this.PineJS.Std.sma(priceChangeAbs, n1, context)) *
        100
      const rsiValue2 =
        (this.PineJS.Std.sma(priceChange, n2, context) /
          this.PineJS.Std.sma(priceChangeAbs, n2, context)) *
        100
      wma1.set(wmaValue1)
      wma2.set(wmaValue2)
      rsi1.set(rsiValue1)
      rsi2.set(rsiValue2)
      // console.log(`WMA1=> ${wma1.get(0)} ${wma1.get(1)}`)
      // console.log(`WMA2=> ${wma2.get(0)} ${wma2.get(1)}`)
      // console.log(`RSI1=> ${rsi1.get(0)} ${rsi1.get(1)}`)
      // console.log(`RSI2=> ${rsi2.get(0)} ${rsi2.get(1)}`)

      //#endregion

      //#region AMA color logic
      ama.set(amaValue)
      // console.log(`ama(${ama.mindepth}): ${ama.get(0)}`)

      mx.set(this.PineJS.Std.lowest(ama, ama.mindepth, context))
      // console.log(`mx(${mx.mindepth}): ${mx.get(0)}`)

      const my = mx.get(mx.mindepth - 1)
      // console.log(`my: ${my}`)

      amb.set(amaValue + Math.abs(my))
      ambDiff.set(amb.get(0) - amb.get(1))
      // console.log(`amb(${amb.mindepth}): ${amb.get(0)}, ${amb.get(1)}`)

      const filter = (k / 100) * this.PineJS.Std.stdev(ambDiff, n, context)
      // console.log(`filter: ${filter}`)

      ss.set(ambDiff.get(0) > 0 ? 1 : ambDiff.get(0) < 0 ? -1 : 0)
      // console.log(`ss(${ss.mindepth}): ${ss.get(0)}`)

      let indexOfHigh = -1
      let indexOfLow = -1

      for (let i = 1; i < ama.mindepth - 1; i++) {
        if (ss.get(i) === 1) {
          indexOfHigh = i
          break
        }
      }

      for (let i = 1; i < ama.mindepth - 1; i++) {
        if (ss.get(i) === -1) {
          indexOfLow = i
          break
        }
      }
      // console.log(`index h: ${indexOfHigh}, index l: ${indexOfLow}`)

      const amaHigh = amb.get(indexOfHigh)
      const amaLow = amb.get(indexOfLow)
      // console.log(`
      // amaHigh: ${amaHigh}
      // amaLow: ${amaLow}
      // `)
      const tt = Number(amb.get(0) - amaLow > filter) - Number(amaHigh - amb.get(0) > filter)
      // console.log(`tt: ${tt}`)

      //#endregion

      const longFlag =
        rsi1.get(0) > rsi2.get(0) &&
        wma1.get(0) > wma2.get(0) &&
        wma1.get(1) <= wma2.get(1) &&
        tt === 1

      const shortFlag =
        rsi1.get(0) < rsi2.get(0) &&
        wma2.get(0) > wma1.get(0) &&
        wma2.get(1) <= wma1.get(1) &&
        tt === -1

      const longFlag2 =
        wma1.get(0) > wma2.get(0) &&
        rsi1.get(0) > rsi2.get(0) &&
        rsi1.get(1) <= rsi2.get(1) &&
        tt === 1

      const shortFlag2 =
        wma1.get(0) < wma2.get(0) &&
        rsi2.get(0) > rsi1.get(0) &&
        rsi2.get(1) <= rsi1.get(1) &&
        tt === -1

      const longTime = dayAPI('2023-05-24 09:35:00')
      const longTime2 = dayAPI('2023-05-24 08:45:00')
      const shortTime = dayAPI('2023-05-24 09:15:00')
      const shortTime2 = dayAPI('2023-05-24 10:40:00')
      const datetime = dayAPI(this.PineJS.Std.time(context))

      if (datetime.isSame(longTime)) {
        console.log(`
        Long1 conditions
        ---------------------------
        rsi1.get(0) > rsi2.get(0) => ${rsi1.get(0) > rsi2.get(0)}   
        wma1.get(0) > wma2.get(0) => ${wma1.get(0) > wma2.get(0)}     
        wma1.get(1) <= wma2.get(1) => ${wma1.get(1) <= wma2.get(1)}
        tt === 1 => ${tt === 1}
        `)
      }

      if (datetime.isSame(longTime2)) {
        console.log(`
        Long2 conditions
        ----------------------------------
        wma1.get(0) > wma2.get(0) => ${wma1.get(0) > wma2.get(0)}
        rsi1.get(0) > rsi2.get(0) => ${rsi1.get(0) > rsi2.get(0)}
        rsi1.get(1) <= rsi2.get(1) => ${rsi1.get(1) <= rsi2.get(1)}
        tt === 1 => ${tt === 1}
        `)
      }

      if (datetime.isSame(shortTime)) {
        console.log(`
        Short1 conditions
        ------------------------------------------------
        rsi1.get(0) < rsi2.get(0) => ${rsi1.get(0) < rsi2.get(0)}
        wma2.get(0) > wma1.get(0) => ${wma2.get(0) > wma1.get(0)}
        wma2.get(1) <= wma1.get(1) => ${wma2.get(1) <= wma1.get(1)}
        tt === -1 => ${tt === -1}
        `)
      }

      if (datetime.isSame(shortTime2)) {
        console.log(`
        Short2 conditions
        ------------------------------------------------
        wma1.get(0) < wma2.get(0) => ${wma1.get(0) < wma2.get(0)}
        rsi2.get(0) > rsi1.get(0) => ${rsi2.get(0) > rsi1.get(0)}
        rsi2.get(1) <= rsi1.get(1) => ${rsi2.get(1) <= rsi1.get(1)}
        tt === -1 => ${tt === -1}
        `)
      }

      return [amaValue, tt === 0 ? 0 : tt === 1 ? 1 : 2, longFlag, longFlag2, shortFlag, shortFlag2]
    },
  },

  metainfo: {
    is_price_study: true,
    _metainfoVersion: 42,
    isTVScript: false,
    isTVScriptStub: false,
    is_hidden_study: false,

    plots: [
      { id: 'ama', type: 'line' },
      {
        id: 'colorer',
        type: 'colorer',
        target: 'ama',
        palette: 'amaPalette',
      },
      { id: 'longFlag', type: 'chars' },
      { id: 'longFlag2', type: 'chars' },
      { id: 'shortFlag', type: 'chars' },
      { id: 'shortFlag2', type: 'chars' },
    ],
    palettes: {
      amaPalette: {
        colors: {
          0: {
            name: 'Color 0',
          },
          1: {
            name: 'Color 1',
          },
          2: {
            name: 'Color 2',
          },
        },
      },
    },
    defaults: {
      styles: {
        ama: {
          linestyle: 0,
          linewidth: 2,
          plottype: 2,
          trackPrice: false,
          transparency: 0,
          visible: true,
          color: '#ff1744',
        },

        longFlag: { color: '#ff1744', textColor: '#ff1744', transparency: 20, visible: true },
        longFlag2: { color: '#ff1744', textColor: '#ff1744', transparency: 20, visible: true },
        shortFlag: { color: '#00A600', textColor: '#00A600', transparency: 20, visible: true },
        shortFlag2: { color: '#00A600', textColor: '#00A600', transparency: 20, visible: true },
      },

      palettes: {
        amaPalette: {
          colors: {
            0: {
              color: '#cbced7',
              width: 3,
              style: 0,
            },
            1: {
              color: '#f13933',
              width: 3,
              style: 0,
            },
            2: {
              color: '#00ba00',
              width: 3,
              style: 0,
            },
          },
        },
      },
      precision: 2,
      inputs: {
        p1: 7,
        p2: 11,
        n1: 60,
        n2: 66,
        n: 10,
        p: 2,
        q: 30,
        k: 15,
      },
    },

    styles: {
      ama: {
        title: 'AMA',
        histogramBase: 0,
        joinPoints: false,
      },

      longFlag: {
        title: '多訊',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多',
      },

      longFlag2: {
        title: '多訊2',
        isHidden: false,
        location: 'BelowBar',
        char: '▲',
        size: 'small',
        text: '多',
      },
      shortFlag: {
        title: '空訊',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空',
      },
      shortFlag2: {
        title: '空訊2',
        isHidden: false,
        location: 'AboveBar',
        char: '▼',
        size: 'small',
        text: '空',
      },
    },
    inputs: [
      {
        id: 'p1',
        name: 'P2',
        defval: 7,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'p2',
        name: 'P2',
        defval: 11,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n1',
        name: 'N1',
        defval: 60,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n2',
        name: 'N2',
        defval: 66,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'n',
        name: 'N',
        defval: 10,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'p',
        name: 'P',
        defval: 2,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'q',
        name: 'Q',
        defval: 30,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
      {
        id: 'k',
        name: 'K',
        defval: 15,
        type: 'integer',
        min: 1,
        max: 500,
        step: 1,
      },
    ],
  },
})
