


//去除空白後的字串
export const removeAfterWhitespace = (str: string) => {
  // 使用split方法将字符串拆分成数组
  const arr = str.split(' ')
  // 使用slice方法从第一个空白后的索引开始截取数组
  const removed = arr.slice(0, 1).join(' ')
  // 使用join方法将数组重新组合成字符串
  const result = removed
  return result
}
