import { css } from '@emotion/react'
import { store } from '~/pages/heineken_template/_private/store'
import { TemplateProps } from '~/pages/heineken_template/_private/TemplateProps'
import TemplateTheme from '~/pages/heineken_template/_private/TemplateTheme'
import SymbolPlatformBase from '../../modules/symbolPlatform/base/SymbolPlatformBase'
import QuoteDetail from '../win988/component/QuoteDetail'

export const asia71178_initStyling = (templateProps: TemplateProps) => {
  templateProps.globalCssset = css`
    #__next {
      ${SymbolPlatformBase.classes.container} {
        &.light {
          box-shadow: 0px 1px 5px 1px #cccccc;
        }
      }

      ${SymbolPlatformBase.classes.header} {
        &.light {
          background-color: #e4e3e3;
        }
      }
      ${QuoteDetail.classes.container} {
        &.light {
          background-color: #ffffff;
        }
      }
    }
  `
}
